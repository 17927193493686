import {
  DOCUMENT_PIPELINE_STATUSES,
  DOCUMENT_BULK_ACTIONS
} from 'admin/constants/docPipelineStatuses';

export const BULK_REPROCESS_ACTIONS_OPTIONS = [
  {
    label: 'Reindex',
    value: DOCUMENT_BULK_ACTIONS.REINDEX.type
  },
  {
    label: 'Rescrape',
    value: DOCUMENT_BULK_ACTIONS.RESCRAPE.type
  },
  {
    label: 'Translate',
    value: DOCUMENT_BULK_ACTIONS.TRANSLATE.type
  },
  {
    label: 'Sentencify',
    value: DOCUMENT_BULK_ACTIONS.SENTENCIFY.type
  },
  {
    label: 'Versionize',
    value: DOCUMENT_BULK_ACTIONS.VERSIONIZE.type
  },
  {
    label: 'Unoffpubdate',
    value: DOCUMENT_BULK_ACTIONS.UNOFFPUBDATE.type
  }
];
