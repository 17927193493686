import type { BulkReprocessModalRule } from './useBulkReprocessModalData.types';
import type { AvailableBulkReprocessActions } from './useBulkReprocessModalFormattedData.types';

import * as constants from '../BulkReprocessModal.constants';

export const isRuleCompleted = ({ action }: BulkReprocessModalRule) => {
  return Boolean(action);
};

export const getAvailableBulkReprocessActions = (
  rules: BulkReprocessModalRule[]
): constants.BULK_REPROCESS_ACTIONS[] => {
  return [
    constants.BULK_REPROCESS_ACTIONS.INDEX,
    constants.BULK_REPROCESS_ACTIONS.RESCRAPE,
    constants.BULK_REPROCESS_ACTIONS.TRANSLATE,
    constants.BULK_REPROCESS_ACTIONS.SENTENCIFY,
    constants.BULK_REPROCESS_ACTIONS.VERSIONIZE,
    constants.BULK_REPROCESS_ACTIONS.UNOFFPUBDATE
  ];
};

export const getAvailableActionsMap = (
  rules: BulkReprocessModalRule[]
): AvailableBulkReprocessActions =>
  Object.fromEntries(
    rules.map(currentRule => {
      const availableActions = getAvailableBulkReprocessActions(
        rules.filter(rule => currentRule.id !== rule.id)
      );
      return [currentRule.id, availableActions];
    })
  );
