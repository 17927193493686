import type { FC } from 'react';
import type { BulkReprocessModalProps } from './BulkReprocessModal.types';

import * as elements from './elements';
import * as uiLib from '@compliance.ai/web-components';

import {
  useBulkReprocessModalData,
  useBulkReprocessModalHandlers,
  useBulkReprocessModalMutations,
  useBulkReprocessModalQueries,
  useBulkReprocessModalFormattedData
} from './hooks';

import './_bulk-reprocess-modal.scss';

export const BulkReprocessModal: FC<BulkReprocessModalProps> = ({
  selectedDocumentIds,
  onSubmit,
  onClose
}) => {
  const { localState, localActions, reduxState } = useBulkReprocessModalData();

  const handlers = useBulkReprocessModalHandlers({
    localState,
    localActions
  });

  const queries = useBulkReprocessModalQueries({
    props: {
      selectedDocumentIds
    }
  });

  const mutations = useBulkReprocessModalMutations({
    props: {
      selectedDocumentIds,
      onSubmit,
      onClose
    },
    localState,
    reduxState,
    queries
  });

  const formattedData = useBulkReprocessModalFormattedData({
    props: {
      selectedDocumentIds
    },
    localState,
    queries,
    mutations
  });

  return (
    <uiLib.Modal
      isOpen
      title={formattedData.modalTitle}
      onClose={onClose}
      onSubmit={mutations.updateDocumentDataMutation.mutate}
      withFooter
      secondaryButtonType={uiLib.BUTTON_TYPES.SECONDARY}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={formattedData.isSubmitButtonDisabled}
      primaryButtonText="Apply reprocessing"
      classNames={{
        title: 'bulk-reprocess-modal__title',
        container: 'bulk-reprocess-modal__container',
        content: 'bulk-reprocess-modal__content',
        footer: 'bulk-reprocess-modal__footer'
      }}
    >
      <div className="bulk-reprocess-modal__rules-container">
        {formattedData.isUpdating && <uiLib.Loader withoutBackground={false} />}
        {formattedData.isRulesListShown && (
          <div className="bulk-reprocess-modal__rules-list">
            {localState.rules.map(rule => (
              <elements.BulkReprocessModalRule
                key={rule.id}
                action={rule.action}
                onActionChange={handlers.handleRuleActionChange(rule.id)}
                availableBulkReprocessActions={formattedData.availableBulkReprocessActions[rule.id]}
              />
            ))}
          </div>
        )}
      </div>
    </uiLib.Modal>
  );
};
