export enum DOCUMENT_PIPELINE_STATUS {
  SCRAPED = 'scraped',
  GENERATED = 'generated',
  NEW = 'new',
  FLAGGED = 'flagged',
  OUT_OF_VERTICAL = 'out_of_vertical',
  RESCRAPED = 'rescraped',
  HTMLPDFCONV = 'htmlpdfconv',
  UPDATED = 'updated',
  HIDDEN = 'hidden',
  ORIGINAL = 'original',
  VERSIONIZED_DOC = 'versionized_doc',
  EXTRACTED = 'extracted',
  SENTENCIFIED = 'sentencified',
  TERM_SAMPLED = 'term_sampled',
  LANGUAGE_DETECTED = 'language_detected',
  SENTENCE_PREDICTED = 'sentence_predicted',
  CLASSIFIED = 'classified',
  PROCESSED = 'processed',
  PROCESSING_HTMLTOPDFCONV = 'processing_htmltopdfconv',
  PROCESSING_DOC_VERSIONIZE = 'processing_doc_versionize',
  PROCESSING_DUPLICATE_FILTERING = 'processing_duplicate_filtering',
  PROCESSING_EXTRACTION = 'processing_extraction',
  PROCESSING_SENTENCIFICATION = 'processing_sentencification',
  PROCESSING_SENTENCE_PREDICTION = 'processing_sentence_prediction',
  PROCESSING_CLASSIFICATION = 'processing_classification',
  PROCESSING_INDEXING = 'processing_indexing',
  PROCESSING_DEPRECATION = 'processing_deprecation',
  PROBLEMATIC = 'problematic',
  DEPRECATED = 'deprecated',
  UNDEPRECATED = 'undeprecated', // this a pseudo pipeline status, we just use it to request undeprecation
  OFF_TOPIC = 'out_of_vertical',
  CLIENT_ONLY = 'client_only',
  INCOMPLETE = 'incomplete',
  HTMLPDFCONVFAILED = 'htmlpdfconvfailed',
  DUPLICATED = 'duplicated',
  POTENTIAL_DUPLICATE = 'potential_duplicate',
  FUTURE_PUB_DATE = 'future_publication_date',
  HOLD = 'hold',
  CONCEPT_TAGGED = 'concept_tagged',
  PROCESSING_TRANSLATION = 'processing_translation'
}

export enum DOCUMENT_BULK_ACTION {
  SENTENCIFY = 'sentencify',
  TRANSLATE = 'translate',
  VERSIONIZE = 'versionize',
  RESCRAPE = 'rescrape',
  UNDUPLICATE = 'unduplicate',
  HIDE = 'hide',
  UNHIDE = 'unhide',
  UNFLAG = 'unflag',
  DEPRECATE = 'deprecate',
  UNDEPRECATE = 'undeprecate',
  REINDEX = 'reindex',
  UNOFFPUBDATE = 'unoffpubdate'
}
export const DOCUMENT_BULK_ACTIONS = {
  REINDEX: { type: DOCUMENT_BULK_ACTION.REINDEX, label: 'Reindex' },
  SENTENCIFY: { type: DOCUMENT_BULK_ACTION.SENTENCIFY, label: 'Sentencify' },
  TRANSLATE: { type: DOCUMENT_BULK_ACTION.TRANSLATE, label: 'Translate' },
  VERSIONIZE: { type: DOCUMENT_BULK_ACTION.VERSIONIZE, label: 'Versionize' },
  RESCRAPE: { type: DOCUMENT_BULK_ACTION.RESCRAPE, label: 'Rescrape' },
  UNDUPLICATE: { type: DOCUMENT_BULK_ACTION.UNDUPLICATE, label: 'Unduplicate' },
  UNHIDE: { type: DOCUMENT_BULK_ACTION.UNHIDE, label: 'Unhide' },
  UNFLAG: { type: DOCUMENT_BULK_ACTION.UNFLAG, label: 'Unflag' },
  HIDE: { type: DOCUMENT_BULK_ACTION.HIDE, label: 'Hide' },
  DEPRECATE: { type: DOCUMENT_BULK_ACTION.DEPRECATE, label: 'Deprecate' },
  UNDEPRECATE: { type: DOCUMENT_BULK_ACTION.UNDEPRECATE, label: 'Undeprecate' },
  UNOFFPUBDATE: { type: DOCUMENT_BULK_ACTION.UNOFFPUBDATE, label: 'Unoffpubdate' }
};
export const DOCUMENT_PIPELINE_STATUSES = {
  SCRAPED: { type: DOCUMENT_PIPELINE_STATUS.SCRAPED, label: 'Scraped' },
  GENERATED: { type: DOCUMENT_PIPELINE_STATUS.GENERATED, label: 'Generated' },
  NEW: { type: DOCUMENT_PIPELINE_STATUS.NEW, label: 'New' },
  FLAGGED: { type: DOCUMENT_PIPELINE_STATUS.FLAGGED, label: 'Flagged' },
  OUT_OF_VERTICAL: { type: DOCUMENT_PIPELINE_STATUS.OUT_OF_VERTICAL, label: 'Out of vertical' },
  RESCRAPED: { type: DOCUMENT_PIPELINE_STATUS.RESCRAPED, label: 'Rescraped' },
  HTMLPDFCONV: { type: DOCUMENT_PIPELINE_STATUS.HTMLPDFCONV, label: 'HTML to PDF conv' },
  UPDATED: { type: DOCUMENT_PIPELINE_STATUS.UPDATED, label: 'Updated' },
  TERM_SAMPLED: { type: DOCUMENT_PIPELINE_STATUS.TERM_SAMPLED, label: 'Term Sampled' },
  LANGUAGE_DETECTED: {
    type: DOCUMENT_PIPELINE_STATUS.LANGUAGE_DETECTED,
    label: 'Language Detected'
  },
  HIDDEN: { type: DOCUMENT_PIPELINE_STATUS.HIDDEN, label: 'Hidden' },
  ORIGINAL: { type: DOCUMENT_PIPELINE_STATUS.ORIGINAL, label: 'Original' },
  VERSIONIZED_DOC: {
    type: DOCUMENT_PIPELINE_STATUS.VERSIONIZED_DOC,
    label: 'Versionized document'
  },
  EXTRACTED: { type: DOCUMENT_PIPELINE_STATUS.EXTRACTED, label: 'Extracted' },
  SENTENCIFIED: { type: DOCUMENT_PIPELINE_STATUS.SENTENCIFIED, label: 'Sentencified' },
  SENTENCE_PREDICTED: {
    type: DOCUMENT_PIPELINE_STATUS.SENTENCE_PREDICTED,
    label: 'Sentence predicted'
  },
  CLASSIFIED: { type: DOCUMENT_PIPELINE_STATUS.CLASSIFIED, label: 'Classified' },
  PROCESSED: { type: DOCUMENT_PIPELINE_STATUS.PROCESSED, label: 'Processed' },
  PROCESSING_HTMLTOPDFCONV: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_HTMLTOPDFCONV,
    label: 'Processing HTML to PDF conv'
  },
  PROCESSING_DOC_VERSIONIZE: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_DOC_VERSIONIZE,
    label: 'Processing doc versionize'
  },
  PROCESSING_DUPLICATE_FILTERING: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_DUPLICATE_FILTERING,
    label: 'Processing duplicate filtering'
  },
  PROCESSING_EXTRACTION: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_EXTRACTION,
    label: 'Processing extraction'
  },
  PROCESSING_TRANSLATION: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_TRANSLATION,
    label: 'Processing translation'
  },
  PROCESSING_SENTENCIFICATION: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_SENTENCIFICATION,
    label: 'Processing sentencification'
  },
  PROCESSING_SENTENCE_PREDICTION: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_SENTENCE_PREDICTION,
    label: 'Processing sentence prediction'
  },
  PROCESSING_CLASSIFICATION: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_CLASSIFICATION,
    label: 'Processing classification'
  },
  PROCESSING_INDEXING: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_INDEXING,
    label: 'Processing indexing'
  },
  PROCESSING_DEPRECATION: {
    type: DOCUMENT_PIPELINE_STATUS.PROCESSING_DEPRECATION,
    label: 'Processing deprecation'
  },
  PROBLEMATIC: { type: DOCUMENT_PIPELINE_STATUS.PROBLEMATIC, label: 'Problematic' },
  DEPRECATED: { type: DOCUMENT_PIPELINE_STATUS.DEPRECATED, label: 'Deprecated' },
  UNDEPRECATED: { type: DOCUMENT_PIPELINE_STATUS.UNDEPRECATED, label: 'Undeprecated' },
  OFF_TOPIC: { type: DOCUMENT_PIPELINE_STATUS.OFF_TOPIC, label: 'Off topic' },
  CLIENT_ONLY: { type: DOCUMENT_PIPELINE_STATUS.CLIENT_ONLY, label: 'Client only' },
  INCOMPLETE: { type: DOCUMENT_PIPELINE_STATUS.INCOMPLETE, label: 'Incomplete' },
  HTMLPDFCONVFAILED: {
    type: DOCUMENT_PIPELINE_STATUS.HTMLPDFCONVFAILED,
    label: 'HTML to PDF conv failed'
  },
  DUPLICATED: { type: DOCUMENT_PIPELINE_STATUS.DUPLICATED, label: 'Duplicated' },
  POTENTIAL_DUPLICATE: {
    type: DOCUMENT_PIPELINE_STATUS.POTENTIAL_DUPLICATE,
    label: 'Potential duplicate'
  },
  FUTURE_PUB_DATE: {
    type: DOCUMENT_PIPELINE_STATUS.FUTURE_PUB_DATE,
    label: 'Future publication date'
  },
  HOLD: { type: DOCUMENT_PIPELINE_STATUS.HOLD, label: 'Hold' },
  CONCEPT_TAGGED: { type: DOCUMENT_PIPELINE_STATUS.CONCEPT_TAGGED, label: 'Concept tagged' }
};
