import _ from 'lodash';
import moment from 'moment';
import { DOCUMENT_GENERAL, ERRORS, RELATION_TYPES } from '../../DocumentDetails.constants';
import { api_getNewsSources } from 'shared/features/sources/newsSources/newsSources.api';
import { api_getJurisdictions } from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import { api_getDocumentsScrapedCategory } from 'shared/features/documentData/documentData.api';
import { api_getAgencies } from 'shared/features/agencyData/agencyData.api.js';
import * as env from '../../../../../../shared/config';

// ----- Load Options -----

const formatSelectOptions = (array, valueName, labelName) =>
  array.map(item => ({
    value: _.get(item, valueName, item),
    label: _.get(item, labelName, item)
  }));

export const loadAgencyOptions = (name, searchValue, responseName) =>
  api_getAgencies({ [name]: searchValue })
    .then(agency => agency.items)
    .then(agency => formatSelectOptions(agency, 'id', responseName));

export const loadJurisdictionsOptions = (name, searchValue, responseName) =>
  api_getJurisdictions({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, 'id', responseName));

export const loadNewsSourceOptions = (name, searchValue, responseName) =>
  api_getNewsSources({ [name]: searchValue })
    .then(newsSource => newsSource.items)
    .then(newsSource => formatSelectOptions(newsSource, 'id', responseName));

export const loadScrapedCategoryOptions = (name, searchValue, responseName) =>
  api_getDocumentsScrapedCategory({ [name]: searchValue })
    .then(cat => cat.items)
    .then(cat => formatSelectOptions(cat, 'id', responseName));

// ---------------- GET Crawl Info ----------------

const getAgencies = docInfo => {
  const docAgencies = _.get(docInfo, 'agencies', []);
  return docAgencies.map(item => ({
    value: _.get(item, 'id'),
    label: _.get(item, 'name')
  }));
};

const getNewsSources = docInfo => {
  const sources = _.get(docInfo, ['mainstream_news', 'news_source'], null);
  const sourceName = _.get(sources, 'name');
  const sourceId = _.get(sources, 'id');
  return sourceName && sourceId ? { value: sourceId, label: sourceName } : null;
};

const getPipelineStatus = docInfo => {
  const status = _.get(docInfo, 'pipeline_status', null);
  return status ? { value: status, label: status } : null;
};

const getCategory = docInfo => {
  const id = _.get(docInfo, 'doc_scraped_category_id', '');
  const name = _.get(docInfo, 'category', '');
  return id && name ? { value: id, label: name } : null;
};

const getJurisdiction = docInfo => {
  const id = _.get(docInfo, 'jurisdiction_id', '');
  const name = _.get(docInfo, 'jurisdiction', '');
  return _.isNumber(id) && name ? { value: id, label: name } : null;
};

const getRelationIds = (docInfo, relationType) => {
  let relations = _.get(docInfo, 'document_relations', []);
  if (relations === null) {
    relations = [];
  }
  const idsList = relations.filter(item => _.get(item, 'relation_type', '') === relationType);
  return idsList.map(item => _.get(item, 'related_doc_id'));
};

// TODO should be removed after full moving doc logic from user_db
// extra info (help us support old logic / tools)
// ----- Start of help extra info -----
// FIXME (following is commented to match AC of https://complianceai.atlassian.net/browse/DATAWEB-137 but could be rolled back if required)
// const getRealDocStatus = docInfo => {
//   let docStatus = _.get(docInfo, 'pipeline_status', '');
//
//   const isHold = _.get(docInfo, ['user_document_updates', 'status']);
//   const isFlagged = _.get(docInfo, ['user_flagged_documents', 'status']);
//   const isFuture = _.get(docInfo, ['document_misc', 'details', 'future_publication_date']);
//
//   if (isHold) docStatus = DOCUMENT_PIPELINE_STATUSES.HOLD.type;
//   else if (isFlagged) docStatus = DOCUMENT_PIPELINE_STATUSES.FLAGGED.type;
//   else if (isFuture) docStatus = DOCUMENT_PIPELINE_STATUSES.FUTURE_PUB_DATE.type;
//
//   return docStatus.toLowerCase();
// };

// also should be fixed [DOCUMENT_GENERAL.pipelineStatus]: getPipelineStatus(...
// also should be fixed checkEditableStatus function
// ----- End of help extra info -----

const formatTime = date => date && ((date && moment(date)) || undefined).toDate();
const getAiUrl = docInfo =>
  `${env.appUrl}/content?overlay=pdf-overlay&summary_id=${_.get(
    docInfo,
    'id',
    ''
  )}&summary_page=summary`;

// convert server crawl info to screen crawl details
export const fromRawInfoToDetails = docInfo => ({
  // input
  [DOCUMENT_GENERAL.docID]: _.get(docInfo, 'id', ''),
  [DOCUMENT_GENERAL.fullText]: _.get(docInfo, 'full_text', null),
  [DOCUMENT_GENERAL.fullXml]: _.get(docInfo, 'full_xml', null),
  [DOCUMENT_GENERAL.summaryText]: _.get(docInfo, 'summary_text', null),
  [DOCUMENT_GENERAL.title]: _.get(docInfo, 'title', null),
  [DOCUMENT_GENERAL.pdfUrl]: _.get(docInfo, 'pdf_url', null),
  [DOCUMENT_GENERAL.webUrl]: _.get(docInfo, 'web_url', null),
  [DOCUMENT_GENERAL.metaTable]: _.get(docInfo, 'meta_table', null),
  [DOCUMENT_GENERAL.apiTable]: _.get(docInfo, 'api_table', null),
  [DOCUMENT_GENERAL.interactionStatus]: _.get(docInfo, 'interaction_status', null),
  [DOCUMENT_GENERAL.officialId]: _.get(docInfo, 'official_id', null),
  [DOCUMENT_GENERAL.docketId]: _.get(docInfo, 'docket_id', null),
  [DOCUMENT_GENERAL.parentId]: _.get(docInfo, 'parent_id', null),
  [DOCUMENT_GENERAL.docScrapedCategoryId]: _.get(docInfo, 'doc_scraped_category_id', null),
  [DOCUMENT_GENERAL.jurisdictionId]: _.get(docInfo, 'jurisdiction_id', null),
  [DOCUMENT_GENERAL.agencyIds]: _.get(docInfo, 'agency_ids', []),
  [DOCUMENT_GENERAL.spiderName]: _.get(docInfo, 'spider_name', null),
  [DOCUMENT_GENERAL.pdfHash]: _.get(docInfo, 'pdf_hash', null),
  [DOCUMENT_GENERAL.shelfId]: _.get(docInfo, 'shelf_id', null),
  [DOCUMENT_GENERAL.rootShelfId]: _.get(docInfo, 'root_shelf_id', null),
  [DOCUMENT_GENERAL.hasUnofficialPublicationDate]: _.get(
    docInfo,
    'has_unofficial_publication_date',
    null
  ),
  [DOCUMENT_GENERAL.fullTextHash]: _.get(docInfo, 'full_text_hash', null),
  [DOCUMENT_GENERAL.newsSourceName]: _.get(
    docInfo,
    ['mainstream_news', 'news_source', 0, 'name'],
    ''
  ),
  [DOCUMENT_GENERAL.document_examination_record]: _.get(docInfo, 'document_examination_record', []),
  [DOCUMENT_GENERAL.documentLanguages]: _.get(docInfo, 'document_languages', []),
  [DOCUMENT_GENERAL.documentLabels]:
    (docInfo?.eitl_labels ?? [])?.map(item => ({
      value: item?.id,
      label: item?.name
    })) || [],
  // enforcement
  [DOCUMENT_GENERAL.enforcementPenalty]: _.get(docInfo, ['enforcement', 'penalty'], null),
  [DOCUMENT_GENERAL.enforcementViolation]: _.get(docInfo, ['enforcement', 'violation'], null),
  [DOCUMENT_GENERAL.enforcementRespondent]: _.get(docInfo, ['enforcement', 'respondent'], null),
  // time
  [DOCUMENT_GENERAL.createdAt]: formatTime(_.get(docInfo, 'created_at', null)),
  [DOCUMENT_GENERAL.updatedAt]: formatTime(_.get(docInfo, 'internal_updated_at', null)),
  [DOCUMENT_GENERAL.effectiveOn]: formatTime(_.get(docInfo, 'effective_on', null)),
  [DOCUMENT_GENERAL.publicationDate]: formatTime(_.get(docInfo, 'publication_date', null)),
  // select
  [DOCUMENT_GENERAL.parentStatus]: getPipelineStatus(_.get(docInfo, 'parent_doc', {})),
  [DOCUMENT_GENERAL.pipelineStatus]: getPipelineStatus({
    pipeline_status: _.get(docInfo, 'pipeline_status', null)
  }),
  [DOCUMENT_GENERAL.jurisdiction]: getJurisdiction(docInfo),
  [DOCUMENT_GENERAL.scrapedCategory]: getCategory(docInfo),
  [DOCUMENT_GENERAL.newsSource]: getNewsSources(docInfo),
  [DOCUMENT_GENERAL.agencies]: getAgencies(docInfo),
  [DOCUMENT_GENERAL.aiUrl]: getAiUrl(docInfo),
  [DOCUMENT_GENERAL.duplicateList]: getRelationIds(docInfo, RELATION_TYPES.DUPLICATE),
  [DOCUMENT_GENERAL.potentialDuplicateList]: getRelationIds(
    docInfo,
    RELATION_TYPES.POTENTIAL_DUPLICATE
  )
});

// function that format document object to send
const fromDateToString = date => date && date.toGMTString();

// pub date returned from BE in YYYY-MM-DD format
const fromPubDateToString = date => date && moment(date).format('YYYY-MM-DD');

export const checkPubDate = date => moment(date) > moment().add(1, 'days') && ERRORS.pubDate;

export const fromDetailsToRawInfo = (newInfo, oldInfo) => {
  const details = {};
  const errors = [];

  const setDifference = ({
    /*
                          setDifference - function to check difference between old and edit data
                            if there is some difference files send to BE for save

                          newPath (required) - array
                            - get new data
                            - path in docDetails (check DOCUMENT_DETAILS const)
                          oldPath (required) - array
                            - get old data for check
                            - path in original data from BE
                            - used like path for set (show the right place)
                          superPath (optional) - array
                            - path to set data for request in special place
                          predefinedValue (optional) - value (string, number, bool, array, object)
                            - takes like new data (helps if data have special format or addition)
                          checkFunction (optional) - function (return string)
                            - function to check that new variable is correct
                            - should get the value and return string error or false
                        */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }) => {
    let valueNew;
    if (_.isBoolean(predefinedValue)) valueNew = predefinedValue;
    else valueNew = predefinedValue || _.get(newInfo, newPath, null);

    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(details, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  setDifference({
    newPath: [DOCUMENT_GENERAL.pipelineStatus, 'value'],
    oldPath: ['pipeline_status']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.title],
    oldPath: ['title']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.publicationDate],
    oldPath: ['publication_date'],
    predefinedValue: fromPubDateToString(_.get(newInfo, DOCUMENT_GENERAL.publicationDate)),
    checkFunction: checkPubDate
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.createdAt],
    oldPath: ['created_at'],
    predefinedValue: fromDateToString(_.get(newInfo, DOCUMENT_GENERAL.createdAt))
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.updatedAt],
    oldPath: ['internal_updated_at'],
    superPath: ['updated_at'],
    predefinedValue: fromDateToString(_.get(newInfo, DOCUMENT_GENERAL.updatedAt))
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.pdfUrl],
    oldPath: ['pdf_url']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.hasUnofficialPublicationDate, 'value'],
    oldPath: ['has_unofficial_publication_date']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.webUrl],
    oldPath: ['web_url']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.scrapedCategory, 'label'],
    oldPath: ['category']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.scrapedCategory, 'value'],
    oldPath: ['doc_scraped_category_id']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.metaTable],
    oldPath: ['meta_table']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.apiTable],
    oldPath: ['api_table']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.jurisdiction, 'label'],
    oldPath: ['jurisdiction']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.jurisdiction, 'value'],
    oldPath: ['jurisdiction_id']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.agencies],
    oldPath: ['agency_ids'],
    // not use get here, case for { agencies: null }
    predefinedValue: (newInfo[DOCUMENT_GENERAL.agencies] || []).map(item => item.value)
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.newsSource, 'value'],
    oldPath: ['mainstream_news', 'news_source_id']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.parentId],
    oldPath: ['parent_id']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.fullText],
    oldPath: ['full_text']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.summaryText],
    oldPath: ['summary_text']
  });
  setDifference({
    newPath: [DOCUMENT_GENERAL.documentLabels],
    oldPath: ['eitl_labels'],
    predefinedValue: (newInfo[DOCUMENT_GENERAL.documentLabels] || []).map(item => ({
      id: item.value,
      name: item.label
    }))
  });

  return { details, errors };
};

export function pickFlaggingParams(params) {
  const { field, notes, relatedDocuments, issueType, issueSeverity } = params;
  return {
    flagged: {
      error: null,
      field,
      issue_severity: issueSeverity.value,
      issue_type: issueType.value,
      notes,
      related_documents: relatedDocuments,
      saveDisabled: false
    }
  };
}

export function pickDuplicateActionParams(params) {
  const { relatedDocuments, action } = params;
  return {
    pipeline_status: action,
    related_documents: relatedDocuments.split(',').map(item => +item)
  };
}

/*
  export only for tests
  if function should be used in other place that this helper
  please export it by place (like export const ...)
*/
export {
  getAgencies,
  getNewsSources,
  getCategory,
  getJurisdiction,
  getAiUrl,
  getPipelineStatus,
  formatTime,
  formatSelectOptions
};
